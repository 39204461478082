// src/components/eventDetails/SponsorInfo.js
import React from "react"

const SponsorInfo = () => (
  <div className="w-full max-w-3xl mx-auto mt-4 bg-white rounded md:max-w-full sm:max-w-full xs:max-w-full">
    <div className="px-4 py-4 mx-auto sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2
          id="sponsors"
          className="text-3xl font-extrabold leading-9 text-center text-gray-900 sm:text-4xl sm:leading-10"
        >
          Food Trucks & Vendors
        </h2>
        <div className="pt-6 mt-6 border-t-2 border-gray-200">
          <p className="text-base leading-6 text-gray-500">
            If you're interested in becoming a vendor and/or have a food truck
            you'd like to have at the event, please contact us @{" "}
            <a
              className="text-blue-500 underline"
              href="mailto:info@savageoutdoordefense.com"
            >
              info@savageoutdoordefense.com
            </a>{" "}
            with the subject line "Vendor". Vendors and Food Trucks will have
            advertisement opportunities as well as a large on-site crowd. Please
            reach out ASAP as spots are limited!
          </p>
          <p className="mt-6 font-bold">Food Trucks:</p>
          <ul className="pl-5 text-base leading-6 text-gray-500 list-disc">
            <li>
              <b>
                <a
                  href="https://www.kona-ice.com/find-a-kona/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Kona Ice Sno Cones
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="http://www.bryanyfa.ffanow.org/default.aspx?ID=22545"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Bryan Young Farmers Kettle Corn
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.whatsthadealbarbecue.com/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  What's Tha Deal BBQ
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://abuomarhalal.com/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Abu Omar Halal
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.facebook.com/JJsSnowconesTX?mibextid=ZbWKwL"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  JJ's Snowcones
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.facebook.com/johnsonjohnsonkountrykooking?mibextid=ZbWKwL"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Johnson & Johnson Kountry Kooking
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://smartdrinks.com/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Smart Drinks
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://maps.app.goo.gl/3wVjaT9xWtrqjmBD9"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Taqueria Romero
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.facebook.com/jimmys.burger.shack.more?mibextid=ZbWKwL"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Jimmy's Burger Shack
                </a>
              </b>
            </li>
          </ul>

          <p className="mt-6 mb-2 font-bold">Vendors:</p>

          <ul className="pl-5 text-base leading-6 text-gray-500 list-disc">
            <li>
              <b>
                <a
                  href="https://www.facebook.com/SoundGuardEarPlugs?mibextid=ZbWKwL"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Sound Guard Custom Hearing Protection
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://thecutaxethrowing.com/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  The Cut Axe Throwing
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.nwtf.org/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  The Southeast Texas Chapter of The National Wild Turkey
                  Federation
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.puppypretzel.com/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Puppy Pretzel Company
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.amway.com/myshop/VeraE?utm_source=email&utm_medium=sharebar&utm_campaign=us_en_7012600003_118213866&utm_content=myshop"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  XS Energy & Sports Nutrition - Vera Enterprises
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.facebook.com/JJsSnowconesTX?mibextid=ZbWKwL"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  JJ's Snowcones - Cotton Candy
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.facebook.com/share/wjMbrHBPLYRdGZKz/?mibextid=A7sQZp"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Layna's Gifts
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.funforkidshouston.com/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Fun For Kids Facepainting Houston
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://linktr.ee/michellesouthern"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  The Coconut Lanai
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.facebook.com/profile.php?id=100054544686399&mibextid=ZbWKwL"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Katelyn's Krazes
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://liveoakwoodworx.com/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Liveoak Woodworx
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="http://www.charitytheclown.com/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Charity & Friends Balloon Art
                </a>
              </b>
            </li>
            <li>
              <b>
                <a
                  href="https://www.facebook.com/profile.php?id=100083542623997&mibextid=ZbWKwL"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Blood, Sweat & Spears
                </a>
              </b>
            </li>
            <li>
              <a
                href="https://www.facebook.com/MagicalMuzzlesandManes?mibextid=ZbWKwL"
                target="_blank"
                className="text-blue-500 underline"
              >
                <b>Magical Muzzles & Manes S.E. TX</b>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/therabbitholecigarbar?mibextid=ZbWKwL"
                target="_blank"
                className="text-blue-500 underline"
              >
                <b>The Rabbit Hole Cigar Bar</b>
              </a>
            </li>
            <li>
              <a
                href="https://www.safehomebcs.com/"
                target="_blank"
                className="text-blue-500 underline"
              >
                <b>Titan Security Solutions</b>
              </a>
            </li>
            <li>
              <a
                href="https://www.bgcbv.org/"
                target="_blank"
                className="text-blue-500 underline"
              >
                <b>Boys & Girls Club of The Brazos Valley</b>
              </a>
            </li>
            <li>
              <b>Edubb's Knife Sharpening</b>
            </li>
          </ul>

          <p className="mt-2 text-center">
            Watch this space as we continue to add businesses
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default SponsorInfo
