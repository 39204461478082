// src/components/eventDetails/CharityInfo.js
import React from "react"

const CharityInfo = () => (
  <div className="w-full max-w-3xl mx-auto mt-4 bg-white rounded md:max-w-full sm:max-w-full xs:max-w-full">
    {" "}
    <div className="px-4 py-4 mx-auto sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2
          id="charities"
          className="text-3xl font-extrabold leading-9 text-center text-gray-900 sm:text-4xl sm:leading-10"
        >
          About Our Charities
        </h2>
        <div className="pt-6 mt-6 border-t-2 border-gray-200">
          <p className="text-base leading-6 text-gray-500">
            <p>
              <b>
                <a
                  href="https://www.nwtf.org/"
                  target="_blank"
                  className="text-blue-500 underline "
                >
                  National Wild Turkey Federation
                </a>
              </b>
              : Founded in 1973, they are a non-profit dedicated to the
              conservation of the wild turkey and the preservation of our
              hunting heritage. All donations will remain in the state of Texas.
            </p>
            <p>
              <b>
                <a
                  href="https://www.district2vfd.org/"
                  target="_blank"
                  className="text-blue-500 underline "
                >
                  Brazos County District 2 VFD
                </a>
              </b>
              : The previous host of the Kurten Fireworks Show, founded in 1977,
              they are dedicated to saving lives and the protection of property
              endangered by fires and other disasters. They primarily serve
              Northeast Brazos County.
            </p>
            <p>
              <b>
                <a
                  href="https://www.facebook.com/BrazosCountyFirstResponders?mibextid=ZbWKwL"
                  target="_blank"
                  className="text-blue-500 underline "
                >
                  Brazos County First Responders Association
                </a>
              </b>
              : Founded in 2014 after the tragic passing of Constable Brian
              Bachmann, the BCFRA is committed to providing aid to the families
              of Brazos County First Responders that become injured or pass away
              in the line of duty.
            </p>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default CharityInfo
