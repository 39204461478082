// src/components/eventDetails/ContactInfo.js
import React from "react"

const ContactInfo = () => (
  <div className="w-full max-w-3xl mx-auto mt-4 bg-white rounded md:max-w-full sm:max-w-full xs:max-w-full">
    {" "}
    <div className="px-4 py-4 mx-auto sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2
          id="contact"
          className="text-3xl font-extrabold leading-9 text-center text-gray-900 sm:text-4xl sm:leading-10"
        >
          Contact Information
        </h2>
        <div className="pt-6 mt-6 border-t-2 border-gray-200">
          <p className="text-base leading-6 text-gray-500">
            If you have any questions or need further information, please don't
            hesitate to reach out to us via email at{" "}
            <a
              href="mailto:info@savageoutdoordefense.com"
              className="font-bold text-blue-500 hover:underline"
            >
              info@savageoutdoordefense.com
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default ContactInfo
