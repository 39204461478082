import React from "react"
import parkingMap from "../../images/parkingmap.jpg"
import eventMap from "../../images/eventmap.jpg"

const ParkingMap = () => (
  <div className="w-full max-w-3xl mx-auto mt-4 bg-white rounded md:max-w-full sm:max-w-full xs:max-w-full">
    <div className="px-4 py-4 mx-auto sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2
          id="parking"
          className="text-3xl font-extrabold leading-9 text-center text-gray-900 sm:text-4xl sm:leading-10"
        >
          Parking Map
        </h2>
        <div className="pt-6 mt-6 border-t-2 border-gray-200">
          <p className="text-base leading-6 text-gray-500">
            Please use the Parking Map to find the best parking spots, handicap,
            and sponsor parking areas, as well as the exit plan for each lot!
          </p>
          <div className="mt-4">
            <img
              src={parkingMap}
              alt="Parking Map"
              className="h-auto max-w-full"
            />
          </div>
        </div>
      </div>
      <div className="max-w-3xl mx-auto mt-6">
        <h2
          id="map"
          className="text-3xl font-extrabold leading-9 text-center text-gray-900 sm:text-4xl sm:leading-10"
        >
          Event Map
        </h2>
        <div className="pt-6 mt-6 border-t-2 border-gray-200">
          <p className="text-base leading-6 text-gray-500">
            Please use the Event Map to locate the Food Park, Games &
            Activities, and the Restrooms!
          </p>
          <div className="mt-4">
            <img
              src={eventMap}
              alt="Parking Map"
              className="h-auto max-w-full"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ParkingMap
