import React from "react"

const EventFAQs = () => (
  <div className="w-full max-w-3xl mx-auto mt-4 bg-white rounded md:max-w-full sm:max-w-full xs:max-w-full">
    {" "}
    <div className="px-4 py-4 mx-auto sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2
          id="faqs"
          className="text-3xl font-extrabold leading-9 text-center text-gray-900 sm:text-4xl sm:leading-10"
        >
          Frequently Asked Questions
        </h2>
        <div className="pt-6 mt-6 border-t-2 border-gray-200">
          <dl>
            <div>
              <dt className="text-lg font-medium leading-7 text-gray-900">
                How much does the event cost?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                This event is FREE to the public. However, donations are
                appreciated and all proceeds go directly to our operating costs
                and then our charities. This is a non-profit event. Donations
                can be sent via Venmo to{" "}
                <a
                  className="font-bold text-blue-500 underline"
                  href="https://venmo.com/SavageOD"
                  target="_blank"
                >
                  @SavageOD
                </a>{" "}
                or be made via cash/check in person at the community center.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Where can I park?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                We have multiple parking areas available, including handicap and
                sponsor parking. Please see the map{" "}
                <a className="text-blue-500 underline" href="#parking">
                  map
                </a>{" "}
                to find parking.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Is there handicap parking?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Absolutely! Spots are limited and it is first come first serve!
                Please check out our{" "}
                <a className="text-blue-500 underline" href="#parking">
                  map
                </a>{" "}
                to view all parking options.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Can I bring personal fireworks?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                No. We assure you that our show will be entertaining enough that
                you won't need them.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Can I bring alcohol?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Yes, but YOU are responsible for making sure you have a safe and
                sober ride home. NO glass containers allowed.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Can I bring food?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                We will have food trucks on site, who are donating part of their
                proceeds to our charities, but yes you are able to bring your
                own food! However, we do not allow propane or charcoal grills on
                site and YOU are responsible for cleaning up after yourself.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Can I bring a cooler?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Yes, absolutely! Just please remember to clean up after yourself
                and NO glass containers.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Are there public restrooms?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Yes! We will have portapotties and hand washing stations spread
                throughout the event grounds.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Can I make a donation to help with the event?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Absolutely! Donations can be made on-site on the day of the
                event, or in advance through Venmo{" "}
                <a
                  className="font-bold text-blue-500 underline"
                  href="https://venmo.com/SavageOD"
                  target="_blank"
                >
                  @SavageOD
                </a>
                . If using Venmo, please use the caption "4th".
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Can I bring my dog?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Although we don't recommend this, as the loud fireworks can be
                frightening and harmful to their ears. You are allowed to bring
                your dog. They must remain on a leash at all times though.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Is there an age limit?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Absolutely not! This is a family friendly, community oriented
                event! We will even have kids activities on the event grounds.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Do you allow vendors?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Yes, we do! If you're interested in reserving a booth please
                send an email with the subject line "Vendor" to{" "}
                <a
                  href="mailto:info@savageoutdoordefense.com"
                  className="font-bold text-blue-500 hover:underline"
                >
                  info@savageoutdoordefense.com
                </a>
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                How do I become a sponsor?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Please send an email with the subject line "Sponsor" to{" "}
                <a
                  href="mailto:info@savageoutdoordefense.com"
                  className="font-bold text-blue-500 hover:underline"
                >
                  info@savageoutdoordefense.com
                </a>
              </dd>
            </div>

            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Don't see your question here?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Email us at{" "}
                <a
                  href="mailto:info@savageoutdoordefense.com"
                  className="font-bold text-blue-500 hover:underline"
                >
                  info@savageoutdoordefense.com
                </a>{" "}
                information!
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
)

export default EventFAQs
