import React from "react"

const EventOverview = () => (
  <div className="w-full max-w-3xl mx-auto bg-white rounded md:max-w-full sm:max-w-full xs:max-w-full">
    <div className="px-4 py-4 mx-auto sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2
          id="overview"
          className="text-3xl font-extrabold leading-9 text-center text-gray-900 sm:text-4xl sm:leading-10"
        >
          Event Overview
        </h2>
        <div className="pt-6 mt-6 border-t-2 border-gray-200">
          <h3 className="text-xl font-bold text-gray-800">
            21st Annual Kurten Fireworks Show & Fundraiser!
          </h3>
          <p className="mt-2 text-base leading-6 text-gray-500">
            Hosted by Savage Outdoor & Defense.
          </p>
          <div className="mt-4">
            <p className="text-base leading-6 text-gray-500">
              Come celebrate America's independence this 4th of July at the
              Kurten Community Center. Come early to take part in our gun
              raffle, food park, yard games, Bounce houses, face painting,
              balloon animals and to check out our vendors! Gates will open at
              5PM and the show will start at 9PM! All proceeds will benefit
              local{" "}
              <a className="text-blue-500" href="#charities">
                charities
              </a>
              , so come out and have a blast! And don't forget to thank our{" "}
              <a className="text-blue-500" href="#sponsors">
                sponsors
              </a>
              , without them none of this would be possible!
            </p>
          </div>
          <p className="mt-2 text-base leading-6 text-gray-500"></p>
          <div className="mt-4">
            <h4 className="text-lg font-semibold text-gray-700">
              Date & Time:
            </h4>
            <p className="text-base leading-6 text-gray-500">
              <div className="font-bold">Thursday, July 4th, 2024 @ 5pm</div>
              <ul className="pl-5 text-base leading-6 text-gray-500 list-disc">
                {" "}
                <li>Gates open at 5 PM</li>
                <li>Gun raffle at 8:30 PM</li>
                <li>Fireworks show at 9 PM</li>
              </ul>
            </p>
          </div>
          <div className="mt-4">
            <h4 className="text-lg font-semibold text-gray-700">Location:</h4>
            <p className="text-base leading-6 text-gray-500">
              <a
                className="font-bold text-blue-700"
                target="_blank"
                href="https://maps.app.goo.gl/jyZJYV7v6rovSHtd8"
              >
                Kurten Community Center (click for directions)
                <br />
                13055 E State Hwy 21, Kurten, TX, 77862
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default EventOverview
