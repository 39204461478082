import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EventOverview from "../components/events/EventOverview"
import EventRules from "../components/events/EventRules"
import EventFAQs from "../components/events/EventFAQs"
import CharityInfo from "../components/events/CharityInfo"
import SponsorInfo from "../components/events/SponsorInfo"
import ContactInfo from "../components/events/ContactInfo"
import ParkingMap from "../components/events/ParkingMap"
import Sponsors from "../components/events/Sponsors"
import Vendors from "../components/events/Vendors"
import Raffle from "../components/events/Raffle"
import JSConfetti from "js-confetti"
import { useLayoutEffect } from "react"
const Confetti = () => {
  useLayoutEffect(() => {
    const jsConfetti = new JSConfetti()

    jsConfetti
      .addConfetti({
        confettiColors: [
          "#ff0a54",
          "#ff477e",
          "#ff7096",
          "#ff85a1",
          "#fbb1bd",
          "#f9bec7",
        ],
        emojis: ["🎆", "🎇", "✨", "🎉", "🧨", "🎊"],
        emojiSize: 100, // Slightly smaller size for better visibility and effect
        confettiNumber: 250, // Increase for a richer and more festive effect
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  return null
}
const ConfettiTwo = () => {
  useLayoutEffect(() => {
    const jsConfetti = new JSConfetti()

    jsConfetti
      .addConfetti({
        confettiColors: [
          "#ff0a54",
          "#ff477e",
          "#ff7096",
          "#ff85a1",
          "#fbb1bd",
          "#f9bec7",
        ],
        confettiNumber: 250, // Increase for a richer and more festive effect
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  return null
}
const EventPage = () => (
  <Layout>
    <SEO
      title="21st Annual Kurten Fireworks Show & Fundraiser!
 | Savage Outdoor & Defense"
    />
    <Confetti />
    <ConfettiTwo />
    <div className="p-8 bg-gray-800">
      <div class="flex justify-center py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-full mx-auto">
          <h2 className="text-3xl font-extrabold leading-9 text-center text-blue-100 sm:text-4xl sm:leading-10">
            21st Annual Kurten Fireworks Show & Fundraiser!
          </h2>
          <div className="text-center text-blue-100">
            <small>
              Have questions? Reach out to us via{" "}
              <a
                href="mailto:events@savageoutdoordefense.com"
                className="text-red-500 hover:text-red-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>email</strong>
              </a>{" "}
              or check our{" "}
              <a href="#faqs" className="text-red-500 hover:text-red-500">
                <strong>FAQs </strong>
              </a>
              section below.
            </small>
          </div>
          <div className="pt-6 mt-6 border-t-2 border-gray-200">
            <div className="justify-between mx-auto align-middle md:grid md:grid-cols-2 md:gap-4 lg:grid lg:grid-cols-2 lg:gap-4 xl:grid xl:grid-cols-2 xl:gap-4 xs:flex sm:flex">
              <ul className="list-none md:mx-auto lg:mx-auto xl:mx-auto">
                <li>
                  <a href="#overview">
                    <h3 className="mb-3 text-lg font-semibold text-red-500 underline">
                      Event Overview
                    </h3>
                  </a>
                </li>
                <li>
                  <a href="#rules">
                    <h3 className="mb-3 text-lg font-semibold text-red-500 underline">
                      Event Rules
                    </h3>
                  </a>
                </li>
                <li>
                  <a href="#faqs">
                    <h3 className="mb-3 text-lg font-semibold text-red-500 underline">
                      FAQ
                    </h3>
                  </a>
                </li>
                <li>
                  <a href="#raffle">
                    <h3 className="mb-3 text-lg font-semibold text-red-500 underline">
                      Gun Raffle
                    </h3>
                  </a>
                </li>
              </ul>

              <ul className="list-none md:mx-auto lg:mx-auto xl:mx-auto">
                <li>
                  <a href="#charities">
                    <h3 className="mb-3 text-lg font-semibold text-red-500 underline">
                      About Our Charities
                    </h3>
                  </a>
                </li>{" "}
                <li>
                  <a href="#sponsors">
                    <h3 className="mb-3 text-lg font-semibold text-red-500 underline">
                      Sponsor Information
                    </h3>
                  </a>
                </li>
                <li>
                  <a href="#contact">
                    <h3 className="mb-3 text-lg font-semibold text-red-500 underline">
                      Contact Information
                    </h3>
                  </a>
                </li>
                <li>
                  <a href="#parking">
                    <h3 className="mb-3 text-lg font-semibold text-red-500 underline">
                      Parking Map
                    </h3>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="max-w-4xl md:max-w-full sm:max-w-full xs:max-w-full w-full mx-auto mt-4 p-4 bg-white shadow rounded">
      <div class="mx-auto py-4 sm:py-16 w-full lg:px-8">
        <div class="mx-auto">
          <EventOverview />
          <EventRules />
          <EventFAQs />
          <Raffle />
          <ParkingMap />
          <CharityInfo />
          <SponsorInfo />
          <Vendors />
          <ContactInfo />
          {/* <Sponsors /> */}
        </div>
      </div>
    </div>
  </Layout>
)

export default EventPage
