import React from "react"

const EventRules = () => (
  <div className="w-full max-w-3xl mx-auto mt-4 bg-white rounded md:max-w-full sm:max-w-full xs:max-w-full">
    {" "}
    <div className="px-4 py-4 mx-auto sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2
          id="rules"
          className="text-3xl font-extrabold leading-9 text-center text-gray-900 sm:text-4xl sm:leading-10"
        >
          Event Rules
        </h2>
        <div className="pt-6 mt-6 border-t-2 border-gray-200">
          <ul className="pl-5 text-base leading-6 text-gray-500 list-disc">
            <li>
              Be kind, friendly, and considerate. This is a family-friendly
              community event. We're all neighbors here, so let's act like it.
            </li>
            <li>Absolutely NO glass containers on the event grounds.</li>
            <li>NO personal fireworks.</li>
            <li>NO trailers on event grounds.</li>
            <li>
              Absolutely NO BBQ pits, grills, or other cooking devices will be
              allowed on event grounds.
            </li>
            <li>
              Please clean up and take care of your own area. This includes
              disposing of all trash.
            </li>
            <li>
              Absolutely NO fires, burn pits, burn barrels, or open flames of
              any kind.
            </li>
            <li>
              Please do not show up any earlier than 4:30PM. The gates will open
              right at 5PM.
            </li>
            <li>
              Absolutely NO fighting, violence, etc. This is a FAMILY event,
              your differences can wait for another day.
            </li>
            <li>
              Please watch your language as this is a family event and will be
              attended by children of all ages.
            </li>
          </ul>
          <div className="mt-2 text-center">
            <p>
              <b>
                Police presence will be provided by Brazos County Sheriff's
                Office
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default EventRules
