// src/components/eventDetails/SponsorInfo.js
import React from "react"
import sunbeltlogo from "../../images/sunbelt.jpg"
import golfcarts from "../../images/aggielandgolfcarts.jpg"
import logotree from "../../images/logo_tree.png"
import ffb from "../../images/FFB.png"
import rykin from "../../images/RyKin.png"
import aggielandstorage from "../../images/aggielandstorage.png"
import rvos from "../../images/rvos.jpg"
import BrazosWifi from "../../images/BrazosWifi.png"
import jmBuildings from "../../images/JM_Buildings.jpg"
import tanglewood from "../../images/Tanglewood_Chritmas_Trees.png"
import wctractor from "../../images/wctractor.jpg"
import wctractorents from "../../images/wctractorents.png"
import qcleaners from "../../images/qcleaners.jpg"
import baitbarn from "../../images/baitbarn.png"
import kh from "../../images/k&h.png"
import gumbys from "../../images/gumbys.png"
import onedge from "../../images/onedge.jpg"
import procycle from "../../images/procycle.jpg"
import kellydozer from "../../images/kellydozer.png"
import concho from "../../images/concho.png"
import thecut from "../../images/thecut.jpg"
import allenhonda from "../../images/allenhonda.jpg"
import savage from "../../images/savage.png"
import bb from "../../images/bb.png"
import livestockcomm from "../../images/livestockcomm.png"
import KurtenLogo from "../../images/KurtenLogo.png"
import bmf from "../../images/bmf.png"
import btu from "../../images/btu.jpg"
import candy95 from "../../images/candy95.png"
import mav from "../../images/mav.png"
import fsb from "../../images/fsb.png"
import wixonauto from "../../images/wixonauto.jpg"
import agcon from "../../images/agcon.png"
import elevation from "../../images/elevation.png"
import elliot from "../../images/elliot.png"
import holleman from "../../images/holleman.png"
import joes from "../../images/joes.jpg"
import sano from "../../images/sano.png"
import texcon from "../../images/texcon.png"
import nextlevel from "../../images/nextlevel.png"
import exotech from "../../images/exotech.jpg"
import horseshoe from "../../images/horseshoe.jpg"
import MPSegg from "../../images/MPSegg.png"

const SponsorInfo = () => (
  <div className="w-full max-w-full mx-auto mt-4 bg-white rounded md:max-w-full sm:max-w-full xs:max-w-full">
    <div className="px-4 py-4 mx-auto sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2
          id="sponsors"
          className="text-3xl font-extrabold leading-9 text-center text-gray-900 sm:text-4xl sm:leading-10"
        >
          Sponsors & Information
        </h2>
        <div className="pt-6 mt-6 border-t-2 border-gray-200">
          <p className="text-base leading-6 text-gray-500">
            If you'd like to become one of the most integral parts to making
            this event happen please contact us @{" "}
            <a
              className="text-blue-500 underline"
              href="mailto:info@savageoutdoordefense.com"
            >
              info@savageoutdoordefense.com
            </a>{" "}
            with the subject line "Sponsorship". Sponsors will enjoy
            advertisement opportunities, VIP parking, and a designated front row
            viewing area.
          </p>
          <div className="mt-4 text-center">
            <section className="mb-8">
              <h2 className="text-2xl font-bold border-b">Sniper Sponsors</h2>
              <div className="flex flex-wrap justify-center">
                {" "}
                <a href="https://www.kurtentexas.com/" target="_blank">
                  <img
                    src={KurtenLogo}
                    className="mx-2 mt-4"
                    width={200}
                    alt="Kurten Texas"
                  />
                </a>
                <a href="https://candy95.com/" target="_blank">
                  <img
                    src={candy95}
                    className="mx-2 mt-4"
                    width={200}
                    alt="Candy 95"
                  />
                </a>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold border-b">
                Sharp-Shooter Sponsors
              </h2>
              <p>- Lonnie Mikulec</p>
              <div className="flex flex-wrap justify-center">
                <img
                  src={rykin}
                  className="mx-2 mt-4"
                  width={200}
                  alt="RyKin"
                />
                <a href="https://brazoswifi.com/" target="_blank">
                  <img
                    src={BrazosWifi}
                    className="mx-2 mt-4"
                    width={200}
                    alt="BrazosWifi"
                  />
                </a>
                <a href="https://www.bubbamoore.org/" target="_blank">
                  <img
                    src={bmf}
                    className="mx-2 mt-4"
                    width={200}
                    alt="Bubba Moore Foundation"
                  />
                </a>
                <a href="https://maverickradio.com/" target="_blank">
                  <img
                    src={mav}
                    className="mx-2 mt-4"
                    width={200}
                    alt="Maverick Radio"
                  />
                </a>
                <a
                  href="https://www.kurtentexas.com/communitycenter/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Kurten Community Center Association
                </a>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold border-b">Marksman Sponsors</h2>
              <div className="flex flex-wrap justify-center">
                <a href="https://www.rvos.com/" target="_blank">
                  <img
                    src={rvos}
                    className="mx-2 mt-4"
                    width={200}
                    alt="RyKin"
                  />
                </a>
                <a href="https://www.khtoilets.com/" target="_blank">
                  <img
                    src={kh}
                    className="mx-2 mt-4"
                    width={200}
                    alt="K&H Portable Toilets"
                  />
                </a>
                <a href="https://aggielandgolfcars.com/" target="_blank">
                  <img
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={250}
                    src={golfcarts}
                    alt="aggielandgolfcarts"
                  />
                </a>
                <a href="https://www.allenhonda.com/" target="_blank">
                  <img
                    src={allenhonda}
                    className="mx-2 mt-4"
                    width={200}
                    alt="Allen Honda"
                  />
                </a>
                <img
                  src={horseshoe}
                  className="mx-2 mt-4"
                  width={200}
                  alt="Horseshoe"
                />
                <a href="https://mpseggfarms.com/" target="_blank">
                  <img
                    src={MPSegg}
                    className="mx-2 mt-4 bg-black"
                    width={200}
                    alt="MPSEGG Farms"
                  />
                </a>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold border-b">
                Quick Draw Sponsors
              </h2>
              <div className="flex flex-wrap justify-center">
                <a href="https://ffin.com/" target="_blank">
                  <img
                    src={ffb}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={250}
                    alt="FFB"
                  />
                </a>
                <a href="https://www.aggielandstorage.com/" target="_blank">
                  <img
                    src={aggielandstorage}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={200}
                    alt="aggieland storage"
                  />
                </a>
                <a
                  href="https://www.sunbeltrentals.com/location/tx/bryan/equipment-tool-rentals/286/?utm_source=gmb&utm_medium=organic&utm_campaign=pc286&y_source=1_MTc2MDY1MjgtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D"
                  target="_blank"
                >
                  <img
                    src={sunbeltlogo}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={200}
                    alt="sunbelt rentalts"
                  />
                </a>
                <a href="https://liveoakwoodworx.com/" target="_blank">
                  <img
                    className="flex-initial col-span-2 px-2 mx-2 mt-4 text-center"
                    width={200}
                    src={logotree}
                    alt="Live Oak Woodworx"
                  />
                </a>
                <a href="https://www.jmbuildings.com/" target="_blank">
                  <img
                    src={jmBuildings}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={200}
                    alt="JM Buildings"
                  />
                </a>
                <a href="https://tanglewoodchristmastrees.com/" target="_blank">
                  <img
                    src={tanglewood}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={200}
                    alt="Tanglewood Christmas Trees"
                  />
                </a>
                <a href="https://www.wctractor.com/" target="_blank">
                  <img
                    src={wctractor}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={200}
                    alt="WC Tractor"
                  />
                </a>
                <a href="https://www.wctractor.com/" target="_blank">
                  <img
                    src={wctractorents}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={200}
                    alt="WC Tractor Rentals"
                  />
                </a>
                <a
                  href="https://maps.app.goo.gl/t2KqUTFG5y8Jm94n6"
                  target="_blank"
                >
                  <img
                    src={qcleaners}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={200}
                    alt="Q Cleaners"
                  />
                </a>
                <a href="https://baitbarnfisheries.com/" target="_blank">
                  <img
                    src={baitbarn}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={200}
                    alt="Bait Barn"
                  />
                </a>
                <a
                  href="https://www.conchoheartshospice.com/"
                  className=""
                  target="_blank"
                >
                  <img
                    src={concho}
                    className="mx-2 mt-4"
                    width={200}
                    alt="Concho Hearts Hospice"
                  />
                </a>
                <a href="https://www.elevationgutters.com/" target="_blank">
                  <img
                    src={elevation}
                    className="mx-2 mt-4"
                    width={250}
                    alt="Elevation Gutters"
                  />
                </a>
                <a href="https://www.kellyburtdozer.com/" target="_blank">
                  <img
                    src={kellydozer}
                    className="mx-2 mt-4"
                    width={200}
                    alt="Kelly Burt Dozer Inc"
                  />
                </a>
                <a
                  href="https://www.facebook.com/bcsprocycle?mibextid=ZbWKwL"
                  target="_blank"
                >
                  <img
                    src={procycle}
                    className="mx-2 mt-4"
                    width={200}
                    alt="Pro Cycle"
                  />
                </a>
                <a href="https://on-edge-materials.com/" target="_blank">
                  <img
                    src={onedge}
                    className="mx-2 mt-4"
                    width={200}
                    alt="On Edge Materials"
                  />
                </a>
                <a href="https://gumbyspizzaaggieland.com/" target="_blank">
                  <img
                    src={gumbys}
                    className="mx-2 mt-4"
                    width={200}
                    alt="Gumby's Pizza"
                  />
                </a>
                <a href="https://thecutaxethrowing.com/" target="_blank">
                  <img
                    src={thecut}
                    className="mx-2 mt-4"
                    width={200}
                    alt="The Cut Axe Throwing"
                  />
                </a>
                <a
                  href="https://www.facebook.com/savagepht?mibextid=ZbWKwL"
                  target="_blank"
                >
                  <img
                    src={savage}
                    className="mx-2 mt-4"
                    width={200}
                    alt="Savage Performance Horses and Therapy"
                  />
                </a>
                <a href="https://www.b-bauto.com/" target="_blank">
                  <img
                    src={bb}
                    className="mx-2 mt-4"
                    width={200}
                    alt="B&B Automotive Services"
                  />
                </a>
                <a
                  href="https://www.brazosvalleylivestock.net/"
                  target="_blank"
                >
                  <img
                    src={livestockcomm}
                    className="mx-2 mt-4"
                    width={200}
                    alt="Brazos Valley Livestock Commission"
                  />
                </a>
                <a href="https://www.btutilities.com/" target="_blank">
                  <img
                    src={btu}
                    className="mx-2 mt-4"
                    width={250}
                    alt="Bryan Texas Utilities"
                  />
                </a>
                <a href="https://www.bediasbank.com/" target="_blank">
                  <img
                    src={fsb}
                    className="mx-2 mt-4"
                    width={250}
                    alt="First State Bank of Bedias"
                  />
                </a>
                <a
                  href="https://www.facebook.com/wixonauto?mibextid=ZbWKwL"
                  target="_blank"
                >
                  <img
                    src={wixonauto}
                    className="mx-2 mt-4"
                    width={250}
                    alt="Wixon Automotive"
                  />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100088097552839&mibextid=LQQJ4d"
                  target="_blank"
                >
                  <img
                    src={agcon}
                    className="mx-2 mt-4"
                    width={250}
                    alt="Ag-con"
                  />
                </a>
                <a href="https://elliottcon.net/contact-us" target="_blank">
                  <img
                    src={elliot}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={250}
                    alt="elliot construction"
                  />
                </a>
                <a href="https://hollemantavern.com/" target="_blank">
                  <img
                    src={holleman}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={250}
                    alt="holleman tavern"
                  />
                </a>
                <a
                  href="https://www.facebook.com/Joesplaceburgers?mibextid=ZbWKwL"
                  target="_blank"
                >
                  <img
                    src={joes}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={250}
                    alt="Joe's place"
                  />
                </a>
                <a href="https://www.sanochemicals.com/" target="_blank">
                  <img
                    src={sano}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={250}
                    alt="Sano Chemicals"
                  />
                </a>
                <a href="https://www.texcon.net/" target="_blank">
                  <img
                    src={texcon}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={250}
                    alt="Texcon"
                  />
                </a>
                <a href="https://nextlevelrepair.us/" target="_blank">
                  <img
                    src={nextlevel}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={250}
                    alt="Next Level Repair"
                  />
                </a>
                <a
                  href="https://maps.app.goo.gl/JtTTqEqfA5xro6Ce9"
                  target="_blank"
                >
                  <p className="text-blue-500 underline">
                    <b>Cedar Creek Masonic Lodge</b>
                  </p>
                </a>
                <a href="https://exotechconstruction.com/" target="_blank">
                  <img
                    src={exotech}
                    className="flex-initial col-span-2 mx-2 mt-4 text-center"
                    width={250}
                    alt="ExoTech Construction"
                  />
                </a>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold border-b">Rookie Sponsors</h2>
              <div className="flex flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center">
                  <a
                    href="https://maps.app.goo.gl/PD8hmjTfSnFiqKqZ9"
                    target="_blank"
                  >
                    <p className="text-blue-500 underline">
                      <b>The Beer Joint</b>
                    </p>
                  </a>
                  <a
                    href="https://www.facebook.com/hotrodsandtackle?mibextid=ZbWKwL"
                    target="_blank"
                  >
                    <p className="text-blue-500 underline">
                      <b>Hotrods & Tackle</b>
                    </p>
                  </a>
                  <a
                    href="https://maps.app.goo.gl/DC4Vbq3t54HHes6k7"
                    target="_blank"
                  >
                    <p className="text-blue-500 underline">
                      <b>Frank's Country Store & BBQ</b>
                    </p>
                  </a>
                  <a
                    href="https://www.davidgardnersjewelers.com/"
                    target="_blank"
                  >
                    <p className="text-blue-500 underline">
                      <b>David Gardner's Jewelers</b>
                    </p>
                  </a>
                  <a
                    href="https://maps.app.goo.gl/Gpcr7PK7x1EDrNQ96"
                    target="_blank"
                  >
                    <p className="text-blue-500 underline">
                      <b>House of Tires</b>
                    </p>
                  </a>
                  <p>
                    <b>- Rusty & Lisa Hankins</b>
                  </p>
                  <p>
                    <b>- Charles King</b>
                  </p>
                  <a href="https://vandykerankin.com/" target="_blank">
                    <p className="text-blue-500 underline">
                      <b>Van Dyke Rankin & Company</b>
                    </p>
                  </a>
                </div>
              </div>
            </section>
          </div>

          <p className="mt-6 font-bold">In-Kind Sponsors:</p>
          <ul className="pl-5 text-base leading-6 text-gray-500 list-disc">
            <li>
              Staffing by:{" "}
              <b>
                {" "}
                <a
                  href="https://stuactonline.tamu.edu/app/organization/profile/public/id/1248/"
                  className="text-blue-500 underline"
                  target="_blank"
                >
                  Texas A&M Ducks Unlimited
                </a>
              </b>
            </li>
            <li>
              Fireworks Display by:{" "}
              <b>
                {" "}
                <a
                  href="https://www.facebook.com/CelestialDisplays?mibextid=ZbWKwL"
                  className="text-blue-500 underline"
                  target="_blank"
                >
                  Celestial Displays
                </a>
              </b>
            </li>
            <li>
              Fire Safety and Emergency Response by:{" "}
              <b>
                <a
                  href="https://www.district2vfd.org/"
                  className="text-blue-500 underline"
                  target="_blank"
                >
                  Brazos County District 2 VFD
                </a>
              </b>
            </li>
            <li>
              Golf Cars by:
              <b>
                {" "}
                <a
                  href="https://aggielandgolfcars.com/"
                  className="text-blue-500 underline"
                  target="_blank"
                >
                  Aggieland Golf Cars
                </a>
              </b>
            </li>
            <li>
              ATVs by:{" "}
              <b>
                <a
                  href="https://www.wctractor.com/"
                  className="text-blue-500 underline"
                  target="_blank"
                >
                  WCTractor & WCTractor Rents
                </a>
              </b>
            </li>
            <li>
              Restrooms & Hand Washing by:{" "}
              <b>
                <a
                  href="https://www.khtoilets.com/"
                  className="text-blue-500 underline"
                  target="_blank"
                >
                  K&H Portable Toilets
                </a>
              </b>
            </li>
            <li>
              Equipment by:{" "}
              <b>
                {" "}
                <a
                  href="https://www.sunbeltrentals.com/location/tx/bryan/equipment-tool-rentals/286/?utm_source=gmb&utm_medium=organic&utm_campaign=pc286&y_source=1_MTc2MDY1MjgtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D"
                  className="text-blue-500 underline"
                  target="_blank"
                >
                  Sunbelt Rentals
                </a>
              </b>
            </li>
            <li>
              Dumpster by:{" "}
              <b>
                <a
                  href="https://www.facebook.com/prontoservicesllc?mibextid=ZbWKwL"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Pronto Services
                </a>
              </b>
            </li>
            <li>
              Bounce Houses by:{" "}
              <b>
                {" "}
                <a
                  href="https://www.bouncealot.net/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  BOUNCE-A-LOT
                </a>
              </b>
            </li>{" "}
            <li>
              BB Gun Gallery by:{" "}
              <b>
                <a
                  href="https://www.nwtf.org/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  National Wild Turkey Federation
                </a>
              </b>
            </li>
            <li>
              Axe Throwing by:{" "}
              <b>
                <a
                  href=" https://thecutaxethrowing.com/"
                  target="_blank"
                  className="text-blue-500 underline "
                >
                  The Cut Axe Throwing
                </a>
              </b>
            </li>
            <li>
              Yard Games by:{" "}
              <b>
                <a
                  href="https://liveoakwoodworx.com/"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Liveoak Woodworx
                </a>
              </b>
            </li>
            <li>
              Website by:{" "}
              <a
                target="_blank"
                className="text-blue-500 underline"
                href="https://enloe.tech"
              >
                <b>Blake Enloe</b>
              </a>
            </li>
            <li>
              Videography by:{" "}
              <a
                target="_blank"
                className="text-blue-500 underline"
                href="http://snapshotbcs.com/"
              >
                <b>Snapshot BCS</b>
              </a>
            </li>
            <li>
              National Anthem by:{" "}
              <a
                target="_blank"
                className="text-blue-500 underline"
                href="https://www.instagram.com/themollymcdonald?igsh=MWFoc3hsc3JiZXUycg=="
              >
                <b>Molly McDonald</b>
              </a>
            </li>
            <li>
              Sound Equipment by:{" "}
              <a
                target="_blank"
                className="text-blue-500 underline"
                href="http://www.aggielanddjs.com/"
              >
                <b>Downtown Event Services</b>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default SponsorInfo
