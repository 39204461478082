import React from "react"

const EventFAQs = () => (
  <div className="w-full max-w-3xl mx-auto mt-4 bg-white rounded md:max-w-full sm:max-w-full xs:max-w-full">
    <div className="px-4 py-4 mx-auto sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2
          id="raffle"
          className="text-3xl font-extrabold leading-9 text-center text-gray-900 sm:text-4xl sm:leading-10"
        >
          Gun Raffle
        </h2>

        <div className="pt-6 mt-6 border-t-2 border-gray-200">
          <h3 className="text-2xl font-extrabold leading-9 text-center text-gray-900 sm:text-4xl sm:leading-10">
            Prizes
          </h3>
          <ul className="pl-5 text-base leading-6 text-gray-500 list-disc">
            <li>Ruger American Ranch Gen 2 6.5CM</li>
            <li>Diamondback DB-15 12” MLOK 5.56x45mm</li>
            <li>Sig Sauer P365 9mm</li>
            <li>Smith & Wesson 642 SS 38 Spl </li>
            <li>Mossberg 500 28" Black 12ga</li>
            <li>Smith & Wesson M&P 380 Shield EZ 380 Auto </li>
            <li>Taurus Judge Public Defender in Black Poly 45LC/.410</li>
            <li>Canik MC9 Black 9mm </li>
            <li>Smith & Wesson Shield Plus 9mm </li>
            <li>Winchester Xpert Threaded Strata Camo 22LR</li>
          </ul>
          <p className="font-extrabold text-center text-gray-900">
            Each winner will receive <u>one</u> free 2hr range session for one
            shooter @ Savage Outdoor & Defense. Additional shooters can be added
            at the standard rate.
          </p>
          <p className="font-extrabold text-center text-gray-900">
            Winners can apply the value of their firearm to another firearm of
            their choosing at the time of pick-up.
          </p>
        </div>

        <div className="pt-6 mt-6 border-gray-200">
          <dl>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                How much are raffle tickets?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Tickets are $10/each!
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                How do I buy a gun raffle ticket?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Gun raffle tickets can be purchased with cash or Venmo at the
                community center on the day of the event, or in advance of the
                event through Venmo{" "}
                <a
                  className="font-bold text-blue-500 underline"
                  href="https://venmo.com/SavageOD"
                  target="_blank"
                >
                  @SavageOD
                </a>
                . If using Venmo, please add the caption "4th gun raffle - First
                & Last Name - Phone #".
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                When is the gun raffle?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Thursday, July 4th, 2024 @ 8:30PM
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                What are the eligibility requirements?{" "}
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                You must be 18 years or older and able to pass a background
                check to enter. If you fail to meet either of these criteria you
                forfeit your ticket and will NOT be refunded.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Where is pick up?
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Pick up will be at{" "}
                <a
                  className="text-blue-700 underline"
                  target="_blank"
                  href="https://maps.app.goo.gl/kNjFCqbhcm2w5RND7"
                >
                  Burdett & Son Outdoor Adventure Shop
                </a>
                . Items must be picked up within 30 days or will be forfeited.
              </dd>
            </div>
            <div className="pt-6 mt-6 border-t border-gray-200">
              <dt className="text-lg font-medium leading-7 text-gray-900">
                Do I have to be present to win?{" "}
              </dt>
              <dd className="pr-12 mt-2 text-base leading-6 text-gray-500">
                Absolutely not! We will contact all non-present winners within 1
                week of the event.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
)

export default EventFAQs
